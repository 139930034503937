.server-card{
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
}

.server-card:hover{
  box-shadow: 0 1rem 1rem rgba(0.4, 0.4, 0.4, 0.4);
}

.cursor-pointer{
  cursor: pointer;
}

.fade-up-animation{
  animation:slideup ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

body {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.btn{
  font-size: 10px !important;
  font-weight: bold !important;
}


@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideup {
  0% {
    margin-top: 100%;
  }

  100% {
    margin-top: 0%;
  }
}

.ag-header-cell-text {
  font-size: 12px !important;
}
.ag-header-cell{
  font-size: 12px !important;
}
div.ag-theme-alpine div.ag-row {
  font-size: 12px !important;
}

.btn-primary {
  background-color: #5f6f44 !important;
  border-color: #5f6f44 !important;
}

.tradery-green{
  color:#5f6f44 !important;
}

.no-focus:focus{
  outline-style: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}